@import './mixins/mixins.scss';

.order-history {
    @include themify($themes) {
        background-color: themed('mainBackgroundColor');  
    }
    min-height: calc(100vh - 57px);
    padding-top: 50px;
    //background: url(/static/media/bg_top_2.850d8c2c.jpg) center no-repeat;
    background-size: cover;
    .header-title{
        @include themify($themes) {
            color: themed('title'); 
        }  
    }
    .order-end-box{
        background-color: rgba(249, 250, 251, 0.8);
        width: 80%;
        margin: auto;
        margin-top: 40px;
        padding: 40px;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    }
    .order-history-container{
        .order-container{
            table{
                background-color: white;
                th, .cell{
                    padding: 5px 8px;
                }
                th{
                    @include themify($themes) {
                        background-color: themed('tableHeader'); 
                        color: themed('tableHeaderColor');
                    } 
                    font-weight: 500;
                    border-top: none;
                    border-bottom: 0px;
                }
                td{
                    padding: 0;
                    color: rgb(0, 0, 0);
                    font-weight: 300;
                    position: relative;
                    border: none;
                }
                tr:nth-child(even){
                    background-color: #f2f2f2;
                }
                tr{
                    cursor: pointer;
                    transition: .1s;
                    &:hover{
                        background-color: rgb(211, 211, 211);
                    }   
                    .row-button{
                        position: absolute;
                        width: 100%;
                        opacity: 0;
                        height: 100%;
                    }
                }
                .row-selected{
                    @include themify($themes) {
                        background-color: themed('tableRowSelected')!important; 
                    } 
                }
            }
        }
        .orders-right{
            table{
                td{
                    padding-right: 25px;
                }
                tr:nth-child(even){  
                    @include themify($themes) {
                        background-color: themed('orderHistoryDetailsOdd'); 
                    }
                }
            }
            padding-top: 2px;
            .summary-details-box{
                padding: 0px 5px;
                margin-bottom: 20px;
                .summary-details{
                    @include themify($themes) {
                        background-color: themed('orderHistoryDetails'); 
                        color:themed('title'); 
                        border-color:themed('orderHistoryDetailsBorderC'); 
                    } 
                    border: .1rem solid;
                    box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
                    padding: 10px;
                    border-radius: 1px;
                }
            }
            .order-item-box{
                padding: 0px 20px 5px 20px;
                .order-item{
                    padding: 10px;
                    background-color: rgb(255,255,255);
                    border-radius: 1px;
                    box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
                    .item-desc{
                        width: 100%;
                        padding-left: 10px;
                    }
                    .title{
                        margin-bottom: .5rem;
                        font-size: 18px;
                    }
                    .order-iteration{
                        position: absolute;
                        top: 4px;
                        left: 5px;
                        color: #282828;
                    }
                    .order-img-box{
                        float: left;
                        width: 110px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        margin: 0px 8px;
                        .order-img{
                            width: 110px;
                            border: 1px solid rgb(224, 224, 224);
                        }
                    }
                } 
            }
            .summary-box{
                padding: 0px 5px;
                .orders-summary{
                    @include themify($themes) {
                        background-color: themed('orderHistoryDetails'); 
                        color:themed('title'); 
                        border-color:themed('orderHistoryDetailsBorderC'); 
                    } 
                    padding: 10px;
                    border: .1rem solid;
                    border-radius: 1px;
                    box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
                    text-align: right;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .order-history {
        padding-top: 30px;
        .order-container {
            padding: 0;
            margin-bottom: 10px;
        }
    }
}
@media (min-width: 576px){
    span, b,td{
        font-size: 13px;
    }
    .title{
        font-size: 15px!important;
    }
    .cell{
        font-size: 12px;
    }
}
@media (max-width: 500px){
    span, b, td{
        font-size: 13px;
    }
    .title{
        font-size: 15px!important;
    }
    .cell{
        font-size: 14px;
    }
}
@media (max-width: 360px) {
    .cell{
        font-size: 12px;
    }
}
@media (max-width: 450px) {
    .order-history {
        .order-history-delivery {
            font-size: 13px;
            margin: 5px 0;
        }
        .order-history-container{
            padding: 1rem;
        }
    }
}
@media (max-width: 576px) {
    .order-history-container{
        padding: 2rem; 
        .orders-right{
            padding: 0rem;
        }
    }
}
@media (min-width: 577px) {
    .order-history-container{
        padding: 3rem;  
    }
}
@media (min-width: 900px){
    span, b,td{
        font-size: 16px;
    }
    .title{
        font-size: 18px!important;
    }
    .cell{
        font-size: 16px;
    }
}