@import "./mixins/mixins.scss";

.budget-history {
    min-height: calc(100vh - 57px);

    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    @include themify($themes) {
        color: themed("budgetColor");
    }
    //background: url(/static/media/bg_top_2.850d8c2c.jpg) center no-repeat;
    background-size: cover;
    padding-left: 10%;
    padding-top: 50px;
    padding-right: 10%;
    .budgetTitles {
        display: flex;
        justify-content: space-between;

        .titleBudgetHistory {
            margin: 0;
            padding-top: 40px;
        }
        .titleBudgetAtTheBegging {
            padding-top: 50px;
            margin-right: 0;
        }
    }
    .remainingBudgetTitle {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
    }
    table {
        margin-top: 1rem;
        background-color: white;
        th,
        .cell {
            padding: 5px 8px;
        }
        th {
            @include themify($themes) {
                background-color: themed("tableHeader");
                color: themed("tableHeaderColor");
            }
            border-top: 0;
            font-weight: 500;
        }
        td {
            padding: 0;
            color: rgb(0, 0, 0);
            font-weight: 300;
            position: relative;
            border: none;
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        td:last-child {
            display: flex;
            justify-content: flex-end;
        }
        tr {
            transition: 0.1s;
            &:hover {
                background-color: rgb(211, 211, 211);
            }
        }
    }
}

@media (max-width: 450px) {
    .budget-history {
        padding: 60px 0 10px 0;
        .budgetTitles {
            display: block;
            padding: 0;
            margin: 0;
            width: 100%;
            font-size: 16px;
        }
        .remainingBudgetTitle {
            font-size: 16px;
        }
        .table {
            padding: 0;
            width: 100%;
            margin: 0;
            th {
                padding: 5px 2px;
            }
        }
        .cell {
            font-size: 12px;
        }
    }
}
@media (max-width: 576px) {
    .budget-history {
        padding: 60px 0 10px 0;
        .budgetTitles {
            display: block;
            padding: 0;
            margin: 0;
            width: 100%;
        }
        tr {
            font-size: 14px;
        }
        .cell {
            font-size: 12px;
        }
    }
    .container-fluid {
        padding: 0rem;
        .orders-right {
            padding: 0rem;
        }
    }
}
