@import "./mixins/mixins.scss";

.client-panel {
    margin-top: 10px;
    margin-bottom: 0px;
    .admin-panel__logged-panel {
        @include themify($themes) {
            background-color: themed("clientPanelBackgroundColor");
        }
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        padding: 20px;
        .logged-panel-header {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        p {
            color: #98a3a8;
            color: black;
            font-size: 16px;
        }
        .divider {
            margin: 15px 0;
            display: block;
            height: 1px;
            @include themify($themes) {
                background: themed("divider");
            }
        }
        .logged-panel__purchase-value {
            .purchase-text {
                .available-budget {
                    font-size: 16px;
                    padding: 15px 0;
                }
                .budget-alert {
                    @include themify($themes) {
                        color: themed("clientPanelColor");
                    }
                }
                .budget-alert-exceeded {
                    @include themify($themes) {
                        color: themed("clientPanelColor");
                    }
                    font-size: 18px;
                }
                .purchase-header {
                    font-size: 16px;
                    padding-top: 15px;
                }
                .blue-value {
                    @include themify($themes) {
                        color: themed("clientPanelColorBlue");
                    }
                    font-size: 18px;
                    font-weight: bold;
                }
                .gray-value {
                    @include themify($themes) {
                        color: themed("clientPanelColor");
                    }
                }
                .y-rem-18 {
                    margin: 0;
                    padding: 0;
                    display: inline-block;
                    background-color: transparent;
                    height: 1.8rem;
                }
                .value {
                    color: #4d4f51;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        .logged-panel__distributors {
            margin-bottom: 15px;
            .distributors-text {
                margin: 0;
                span {
                    font-weight: bold;
                }
            }
            .distributors-btn {
                width: 130px;
                @include themify($themes) {
                    border-color: themed("clientPanelColor");
                }
                border-style: solid;
                border-width: 0.1rem;
                margin: 5px 0;
                font-size: 12px;
            }
        }
        .logged-panel__status {
            span {
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 1518px) {
    .logged-panel-btn-group {
        .button-basket {
            font-size: 13px;
            padding: 6px;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .client-panel {
        margin: 9px 0 0 0;
    }
}
@media (max-width: 991.98px) {
    .cp-parent {
        padding: 0;
    }
}
@media (min-width: 991.98px) and (max-width: 1300px) {
    .cp-parent {
        padding-right: 5px;
    }
}
