@import "./mixins/mixins.scss";

.navbar {
    .navbar-nav{
        border:.1rem solid;
        @include themify($themes) {
            border-color: themed("navbarBorderColor");
        }
        .nav-tabs{
            padding-bottom: 2px;
            padding-right: 2px;
        }
        border-radius: 1px;
    }
    .dropdown-item {
        width: 98%;
        position: relative;
        left: 1px;
    }
    @include themify($themes) {
        background-color: themed("navbarBackgroundColor");
    }
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    .nav-tabs,
    .nav-link {
        border: none;
        @include themify($themes) {
            color: themed("navbarFontColor");
        }
        &:hover, &:active{
            @include themify($themes) {
                color: themed("navbarFontColorH");
            }
        }
        &:focus{
            @include themify($themes) {
                color: themed("navbarFontColor");
            }
        }
        font-size:16.5px;
    }
    .nav-link.active {
        background-color: transparent;
    }
    .nav-item {
        .dropdown {
            position: absolute;
            margin-right: 200px !important;
        }
        .active {
            @include themify($themes) {
                color: themed("navbarFontColorH");
            }
            text-shadow: 0px 0px 1px rgba(0, 0, 0, .5);
        }
    }
    .nav-link {
        transition: 0.3s;
        background-color: unset !important;
    }
    .lang-select-btn {
        width: 61px;
        border-radius: 0;
        @include themify($themes) {
            color: themed("navbarLangFontColor");
            background-color: themed("navbarLangBgColor");
            //  border: 1px solid themed("navbarLangBorderColor");
        }
        border: 1px solid transparent;
        padding-left:8px;
    }
    .item-separated {
        padding-left: 8px;
        position: relative;
    }
    .item-separated:not(:last-child) {
        &:after {
            position: absolute;
            margin-left: 4px;
            content: "|";
            right: -4px;
            top: 13px;
            font-size: 10px;
            @include themify($themes) {
                color: themed("navbarFontColor");
            }
        }
    }
}

@media (max-width: 450px) {
    .navbar-brand {
        margin: 0px;
    }
}
@media (max-width: 991px) {
    .navbar-nav{
        border:.1rem solid;
        @include themify($themes) {
            border-color: themed("navbarBackgroundColor")!important;
        }
    }
}

