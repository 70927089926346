@import "./mixins/mixins.scss";

.client-side {
    //background: url(../images/bg_top_2.jpg) center no-repeat;
    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    background-size: cover;
    position: relative;
    margin: 0 auto;
    min-height: calc(100vh - 57px);
    padding: 90px 10px 0 10px;
    .search-panel {
        height: 42px;
        @include themify($themes) {
            background-color: themed("searchPanelBg");
            color: themed("searchPanelColor");
        }
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        margin-left: -11px;
        margin-right: -10px;
        display: flex;
        justify-content: flex-end;
        padding: 2px 5px 0px 5px;
        .panel-left {
            //   display: flex;
            display: flex;
            align-items: center;
            padding: 0;
            margin-right: 5px;
        }
        .panel-right {
            display: flex;
            align-items: center;
            text-align: right;
            padding: 0;
            .search-box {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }
        .search-input::placeholder {
            opacity: 0.5;
        }
        .search-input {
            border-radius: 2px;
            border: 1px solid rgb(209, 209, 209);
            height: 29px;
            padding-left: 2px;
            width: 60%;
            color: gray;
            &:focus {
                outline: none;
            }
        }
        .search-button {
            width: 50px;
            height: 29px;
            border-radius: 2px;
            border: 1px solid rgb(209, 209, 209);
            background-color: white;
            position: relative;
            left: -1px;
            color: gray;
            transition: 0.3s;
        }
    }
    .card-container {
        .card {
            @include themify($themes) {
                background-color: themed("cardBackgroundColor");
            }
            overflow: hidden;
            border: none;
            height: 364px;
            border-radius: 0;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
            .card-body {
                .card-title {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                a {
                    .card-title {
                        font-size: 1.1rem;
                        @include themify($themes) {
                            color: themed("productTitle");
                        }
                    }
                }
                .availability-alert {
                    color: #d81e1e;
                }
            }
            .card-label-box {
                position: absolute;
                width: 100px;
                height: 100px;
                overflow: hidden;
                .card-label {
                    @include themify($themes) {
                        background-color: themed(
                            "extraTagLabelBackgroundColor"
                        );
                        color: themed("extraTagLabelFontColor");
                    }
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: pre;
                    width: 140px;
                    font-weight: bold;
                    transform: rotate(45deg);
                    white-space: pre-wrap;
                }
            }
        }
        .buttons-container {
            margin: 7px auto 7px auto;
            .product-input {
                width: 60px;
                height: 33px;
                padding: 0 0 0 8px;
                border-radius: 1px;
                border-width: 0.1rem;
            }
        }
    }
    .navigation-pager {
        margin-top: 1.5rem;
        .page-item {
            .page-link {
                @include themify($themes) {
                    color: themed("pagerFontC");
                    background-color: themed("pagerBgC");
                    border-color: themed("pagerBorderC");
                }
                border: 2px solid;
                svg {
                    @include themify($themes) {
                        color: themed("pagerFontC");
                    }
                }
            }
            &:hover {
                .page-link {
                    @include themify($themes) {
                        border-color: themed("pagerBorderCH") !important;
                    }
                    border: 2px solid !important;
                }
            }
        }
        .page-item.active {
            .page-link {
                @include themify($themes) {
                    background-color: themed("pagerActiveBg");
                    border-color: themed("pagerActiveBorderC");
                    color: white;
                }
                background-color: #a0a3a6;
                border-color: #a0a3a6;
            }
        }
    }
}

@media (min-width: 1839px) {
    .client-side {
        padding: 90px 50px 0 50px;
    }
}
@media (max-width: 2512px) {
    .search-panel {
        max-width: 1660px;
    }
}
@media (max-width: 2110px) {
    .search-panel {
        max-width: 1325px;
    }
}
@media (max-width: 1838px) {
    .client-side {
        padding: 70px 16px 0 24px;
    }
}
@media (max-width: 600px) {
    .search-input {
        width: 85% !important;
    }
}
@media (max-width: 380px) {
    .search-button {
        width: 30px !important;
    }
}
@media (max-width: 991px) {
    .client-side {
        .card-container {
            display: flex;
            justify-content: center;
        }
    }
}

@media (min-width: 992px) {
    .card {
        flex: none;
    }
}
@media (min-width: 451px) {
    .quantity {
        font-size: 14px;
    }
    .card-label-box {
        right: 0px;
        top: -1px;
        .card-label {
            left: -8px;
            top: 24px;
            height: 30px;
            line-height: 12px;
            .label-textarea {
                width: 85px;
                font-size: 13px;
            }
        }
    }
}
@media (max-width: 450px) {
    .card {
        height: 278px !important;
        .quantity {
            font-size: 12px;
        }
    }
    .client-panel {
        margin-top: 20px;
    }
    .client-side {
        padding: 50px 15px;
        .container-fluid {
            .buttons-container {
                padding-left: 0px;
                .product-input {
                    font-size: 11px;
                    padding: 0;
                    width: 60px;
                }
                .product-basket-icon {
                    font-size: 0.8em;
                }
            }
        }
    }
    .card-label-box {
        right: -2px;
        top: -1px;
        .card-label {
            left: 1px;
            top: 17px;
            height: 22px;
            line-height: 8px;
            .label-textarea {
                width: 65px;
                font-size: 8px !important;
            }
        }
    }
}
