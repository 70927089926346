/*! `Custom` Bootstrap 4 theme */
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);

$primary: #666;
$secondary: #ccc;
// $success: #28a745;
// $danger: #dc3545;
// $info: #17a2b8;
// $warning: #ffc107;
// $light: #f8f9fa;
// $dark: #343a40;
// $spacer: 1rem;
$border-width: 2px;
$btn-border-radius: 0;
$navbar-light-color: #a0a3a6;
$navbar-light-hover-color: black;

@import "../../../../node_modules/bootstrap/scss/bootstrap";

// Add SASS theme customizations here..

@import "../mixins/mixins.scss";

.icon-anim {
    &:hover {
        transform: scale(1.03);
        transition: transform 0.3s;
        @include themify($themes) {
            color: themed("basketIconHoverColor");
        }
    }
}
.btn-outline-primary {
    border-radius: 1px;
    border-width: 0.1rem;
}
// No color change on click
.primary-no-action {
    outline: none;
    border: 0.1rem solid;
}
.btn {
    cursor: pointer;
}
.invisible {
    display: none;
}
.f-17 {
    font-size: 1.7rem;
}
.f-09 {
    font-size: 0.9rem;
}
.w-90 {
    width: 90%;
}
// Hide arrows - input[number]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.loading-box {
    border: 1px solid gray;
    height: 26px;
    position: relative;
    .loading-bar {
        position: absolute;
        height: 24px;
        @include themify($themes) {
            background-color: themed("loadingBar");
        }
        transition: 0.3s;
        width: 1%;
        top: 0px;
    }
    .loading-percent {
        position: absolute;
        z-index: 1;
    }
}
.border-button {
    border: solid 0.1rem;
    @include themify($themes) {
        border-color: themed("borderBtnBorderColor");
        color: themed("borderBtnColor");
        &:hover {
            background-color: themed("borderBtnBgH");
            color: themed("borderBtnColorH");
        }
    }
}
.btn-full-button,
.full-button {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    @include themify($themes) {
        border-color: themed("fullBtnBorderC");
        background-color: themed("fullBtnBg");
        color: themed("fullBtnColor");
        &:hover {
            border-color: themed("fullBtnBorderCH");
            background-color: themed("fullBtnBgH");
        }
    }
}
@media (max-width: 600px) {
    .m-desktop {
        display: none !important;
    }
    .m-desktop-flex {
        display: none !important;
    }
    .m-mobile {
        display: block !important;
    }
}
@media (min-width: 601px) {
    .m-desktop {
        display: block !important;
    }
    .m-desktop-flex {
        display: flex !important;
    }
    .m-mobile {
        display: none !important;
    }
}
