.regulations {
    //  text-align: center;
    padding-top: 80px;
    margin: 0 5% 2% 5%;
    min-height: calc(100vh - 57px);
    h2 {
        text-align: center;
        margin-bottom: 1em;
    }
}
