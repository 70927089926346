@import "./mixins/mixins.scss";

.availability-check {
    @include themify($themes) {
        background-color: themed("btnAvailabilityBg");
        border-color: themed("btnAvailabilityBg");
        color: themed("btnAvailabilityFontBg");
    }
    font-size: 14px;
    cursor: pointer;
    height: 34px;
    display: flex;
    color: white;
    background-color: #a0a3a6;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    border: 0.1rem solid;
    padding: 3px 8px 5px 8px;
    border-radius: 1px;
    width: 100%;
    /* margin-left: 1.2rem;
    margin-right: 1.2rem; */
    margin: 0;
    border-right: none;
    border-left: none;
    height: 50px;
    &:hover {
        @include themify($themes) {
            background-color: themed("btnAvailabilityBgH");
            border-color: themed("btnAvailabilityBgH");
        }
        background-color: #96989b;
        border: 0.1rem solid;
    }
    /* &:active{
        background-color:#96989b;
    } */
}
