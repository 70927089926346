$wixOrange: rgb(255, 182, 15);
$wixOrangeHover: rgb(255, 192, 15);
$wixOrangeDarker: rgb(189, 138, 20);
$wixBlack: rgb(35, 31, 32);
$wixDarkGray: rgb(42, 42, 42);
$lightNav: #fafafa;
$lightBgC: rgb(230, 230, 230);
$gray10: #f7f7f7;
$gray9: #f2f2f2;
$gray8: #ced4da;
$gray5: #a0a3a6;
$gray4: #96989b;
$gray3: gray;
$gray2: #666;
$white: white;
$promotionTag: #556e81;
$blue: #035899;
$grayBorder: rgb(102, 102, 102);
$red: #d81e1e;
$blue2: rgb(3, 88, 153);
$basket: #8b8e91;
$hr: rgba(0, 0, 0, 0.1);
$transparent: transparent;
$rowSelected: rgb(215, 215, 215);
$blue3: rgb(0, 76, 151);
$wixOrange: rgb(255, 182, 15);

$themes: (
    light: (
        //all{
            mainBackgroundColor: $lightBgC,
        spinner: $wixBlack,
        title: $wixBlack,
        tableHeader: $gray3,
        tableHeaderColor: $white,
        divider: $lightBgC,
        hrBorderC: $hr,
        endBoxBg: $gray10,
        tableRowSelected: $rowSelected,
        loadingBar: rgba(0, 0, 0, 0.1),
        fullBtnColor: $white,
        fullBtnBg: $gray4,
        fullBtnBgH: $gray3,
        fullBtnBorderC: $gray4,
        fullBtnBorderCH: $gray3,
        borderBtnBgH: $gray3,
        borderBtnColor: $gray2,
        borderBtnColorH: $white,
        borderBtnBorderColor: $gray3,
        basketIconHoverColor: $blue3,
        basketIconHoverColorCP: $blue3,
        //nav-menu{
            navbarBackgroundColor: $lightNav,
            navbarBorderColor:$lightNav,
        navbarFontColor: $gray2,
        navbarFontColorH:$wixBlack,
        navbarLangFontColor: black,
        navbarLangBorderColor: $gray5,
        navbarLangBgColor:$lightNav,
        //}
            //client-panel{
            cardBackgroundColor: $white,
        extraTagLabelBackgroundColor: $promotionTag,
        extraTagLabelFontColor: $white,
        btnAvailabilityBg: $gray5,
        btnAvailabilityBC: $gray5,
        btnAvailabilityBgH: $gray4,
        btnAvailabilityFontBg: $white,
        pagerBgC: $white,
        pagerBorderC: $lightBgC,
        pagerBorderCH: $gray5,
        pagerActiveBg: $gray5,
        pagerActiveBorderC: $gray5,
        pagerFontC: $gray2,
        searchPanelBg: $lightNav,
        searchPanelColor: $gray3,
        //}
            //products {
            productTitle: $blue3,
        //}
            //product-details{
            productDetailsInfoPanelBg: $lightNav,
        //}
            //basket-container{
            clearButtonColor: $gray3,
        clearButtonColorH: $white,
        //}
            //basket{
            basketItemQuantityBg: $blue,
        basketDescBg: $white,
        basketDescColor: $wixBlack,
        basketDescBorderC: $white,
        basketDescInputBorderC: $gray8,
        basketTitle: $blue3,
        //}
            //basket-summary{
            basketSummaryBg: $white,
        backetSummaryBorderC: $white,
        backetSummaryFontC: $wixBlack,
        //}
            //order-options{
            orderOptionsBg: $gray10,
        orderOptionsBtnBg: $gray10,
        orderOptionsBtnBgH: $gray2,
        orderOptionsBtnFontC: $gray2,
        orderOptionsBtnFontCH: $white,
        orderOptionsBtnBorderC: $gray2,
        //}
            //order-history{
            orderHistoryDetails: $lightNav,
        orderHistoryDetailsBorderC: $lightNav,
        orderHistoryDetailsOdd: $gray9,
        //}
            //footer{
            footerBgC: $lightNav,
        footerFontC: $gray5,
        footerFontCH: $wixBlack,
        footerBorderC: $lightNav,
        //}
            //{budgetHistory{
            color: $wixBlack,
        budgetBgC: $gray2,
        //}
            //productDetails{
            prodDetailsC: $gray2,
        prodDetailsBorder: $transparent,
        prodDetailsTextC: $wixBlack,
        prodDetailsBtnContC: $lightNav,
        prodDetailsBtnHoverBgC: $grayBorder,
        prodDetailsBtnHoverC: $white,
        //}
            //carousel{
            carouselC: $blue,
        //}
            //clientPanel{
            clientPanelColor: $red,
        clientPanelColorBlue: $blue2,
        clientPanelBackgroundColor: $lightNav,
        clientPanelDivider: #e4e6e7,
        basketColorHover: #8b8e91,
        basketColor: $gray5 //},,,,,,,,,,,,,,,,
    ),
    // ..............................................................................
        dark: (
            //all{
                mainBackgroundColor: $wixBlack,
            spinner: $white,
            title: $white,
            tableHeader: $wixOrange,
            tableHeaderColor: $wixBlack,
            divider: $hr,
            hrBorderC: $wixOrange,
            endBoxBg: $white,
            tableRowSelected: $rowSelected,
            loadingBar: rgb(255, 182, 15),
            fullBtnColor: $wixBlack,
            fullBtnBg: $wixOrange,
            fullBtnBgH: $wixOrangeHover,
            fullBtnBorderC: $wixOrange,
            fullBtnBorderCH: $wixOrangeHover,
            borderBtnBgH: $wixOrange,
            borderBtnColor: $wixOrange,
            borderBtnColorH: $wixBlack,
            borderBtnBorderColor: $wixOrange,
            basketIconHoverColor: $wixOrange,
            basketIconHoverColorCP: $wixBlack,
            //}
                //nav-menu{
                navbarBackgroundColor: $wixBlack,
            navbarFontColor: white,
            navbarFontColorH:$wixOrange,
            navbarBorderColor:$wixOrange,
            navbarLangFontColor: black,
            navbarLangBorderColor: $wixBlack,
            navbarLangBgColor:$wixOrange,
            //}
                //client-panel{
                cardBackgroundColor: $white,
            extraTagLabelBackgroundColor: $wixOrange,
            extraTagLabelFontColor: $wixBlack,
            btnAvailabilityBg: $wixOrange,
            btnAvailabilityBC: $wixBlack,
            btnAvailabilityBgH: $wixOrangeHover,
            btnAvailabilityFontBg: $wixBlack,
            pagerBgC: $wixOrange,
            pagerBorderC: $wixOrangeHover,
            pagerBorderCH: $wixOrangeDarker,
            pagerActiveBg: $wixOrangeDarker,
            pagerActiveBorderC: $wixOrangeDarker,
            pagerFontC: $wixBlack,
            searchPanelBg: $wixOrange,
            searchPanelColor: $wixBlack,
            //}
                //products {
                productTitle: $wixBlack,
            //}
                //basket-container{
                clearButtonColor: $gray3,
            clearButtonColorH: $white,
            //}
                //basket{
                basketItemQuantityBg: $wixBlack,
            basketDescBg: $wixBlack,
            basketDescColor: $white,
            basketDescBorderC: $wixOrange,
            basketDescInputBorderC: $wixOrange,
            basketTitle: $white,
            //}
                //basket-summary{
                basketSummaryBg: $wixBlack,
            backetSummaryBorderC: $wixOrange,
            backetSummaryFontC: $white,
            //}
                //order-options{
                orderOptionsBg: $wixOrange,
            orderOptionsBtnBg: $wixDarkGray,
            orderOptionsBtnBgH: $wixBlack,
            orderOptionsBtnFontC: $white,
            orderOptionsBtnFontCH: $white,
            orderOptionsBtnBorderC: $wixDarkGray,
            //}
                //order-history{
                orderHistoryDetails: $wixBlack,
            orderHistoryDetailsBorderC: $wixOrange,
            orderHistoryDetailsOdd: $wixDarkGray,
            //}
                //footer{
                footerBgC: $wixOrange,
            footerFontC: $wixBlack,
            footerFontCH: $wixBlack,
            footerBorderC: $wixOrangeDarker,
            //}
                //{budgetHistory{
                budgetColor: $white,
            budgetBgC: $wixOrange,
            //}
                //productDetails{
                prodDetailsC: $white,
            prodDetailsBorder: $wixOrange,
            prodDetailsTextC: $wixOrange,
            prodDetailsBtnContC: $wixBlack,
            prodDetailsBtnHoverBgC: $wixOrange,
            prodDetailsBtnHoverC: $wixBlack,
            //}
                //carousel{
                carouselC: $wixOrange,
            //}
                //clientPanel{
                clientPanelColor: rgb(35, 31, 32),
            clientPanelColorBlue: rgb(35, 31, 32),
            clientPanelBackgroundColor: rgb(255, 182, 15),
            clientPanelDivider: white,
            basketColorHover: #a0a3a6,
            basketColor: #8b8e91,
            //}
                //buttons
                btnBgC: $wixOrange,
            btnBgCH: $wixOrangeHover //,,,,,,,,,,,,,
        ),
);
