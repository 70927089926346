@import './mixins/mixins.scss';

.spinner{
    @include themify($themes) {
        color: themed('spinner');  
    }
    position: fixed;
    top: 46%; 
    left: 48%;
    width: 70px;
    height: 70px; 
    z-index:9999;
}