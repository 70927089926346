@import "./mixins/mixins.scss";

.basket-container {
    min-height: calc(100vh - 57px);
    //background: url(../images/bg_top_2.jpg) center no-repeat;
    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    background-size: cover;
    .clear-button-box {
        margin: 0 auto;
        .clear-button {
            @include themify($themes) {
                color: themed("clearButtonColor");
                &:hover {
                    color: themed("clearButtonColorH");
                    text-decoration: none;
                    .clear-icon {
                        color: themed("clearButtonColorH");
                    }
                }
            }
            display: inline-block;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            background-color: transparent;
            line-height: 1.4;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            font-size: 16px;
            padding: 0 0 0.2rem 0;
            .icon-anim {
                font-size: 20px;
                padding-top: 5px;
                .clear-icon {
                    margin-left: 3px;
                    transition: color 0.3s;
                    font-size: 29px;
                }
            }
        }
    }
    .basket-header {
        @include themify($themes) {
            color: themed('title');  
        }
        padding-left: 0;
        margin: 0 auto;
        margin-top: 80px;
    }
    h4 {
        font-size: 20px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .client-panel-menu {
        margin: 0 auto;
        width: 87%;
    }
}
@media (min-width: 769px) {
    .clear-button-box {
        width: 95%;
    }
    .basket-header {
        padding-left: 4.9vw;
        margin-left: 1.5rem;
    }
}
@media (max-width: 991px) {
    .cart-collection {
        padding: 0 30px;
    }
}

@media (max-width: 767px) {
    .basket-header {
        padding-left: 0;
        margin-left: 0;
    }
    .cart-collection {
        padding: 0;
    }
    .basket-header {
        padding-left: 10px;
    }
}
@media (max-width: 767px) {
    .clear-button-box {
        width: 100%;
    }
}
