@import "./mixins/mixins.scss";

.order-end {
    //background: url(../images/bg_top_2.jpg) center no-repeat;
    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    background-size: cover;
    min-height: calc(100vh - 57px);
    padding-top: 150px;
    .order-end-box {
        @include themify($themes) {
            background-color: themed("endBoxBg");
        }
        width: 80%;
        margin: auto;
        padding: 40px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    }
}
