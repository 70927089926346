@import "./mixins/mixins.scss";

.product-details {
    //background: url(../images/bg_top_2.jpg) center no-repeat;
    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    background-size: cover;
    position: relative;
    padding: 70px 16px 0px 24px;
    min-height: calc(100vh - 57px);
    .client-panel {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .product-details-container {
        .product-details-content {
            margin-top: 22px;
            .navButtons {
                .right-buttons {
                    text-align: right;
                }
                .btn-prev {
                    margin-right: 1rem;
                }
            }
            .product-details-desc {
                @include themify($themes) {
                    background-color: themed("prodDetailsBtnContC");
                    border-color: themed("prodDetailsBorder");
                    color: themed("prodDetailsC");
                }
                border-style: solid;
                border-width: 1.5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .product-input {
                    border-width: 0.1rem;
                }
                .product-details-header {
                    font-weight: bold;
                    text-transform: uppercase;
                    @include themify($themes) {
                        color: themed("basketTitle");
                    }
                }
                .product-details-text {
                    //text-transform: uppercase;
                    font-size: 15px;
                    color: black;
                    @include themify($themes) {
                        color: themed("prodDetailsTextC");
                    }
                    font-weight: bold;
                }
                .product-buttons-container {
                    @include themify($themes) {
                        background-color: themed("prodDetailsBtnContC");
                    }
                    width: 230px;
                    padding-left: 14px;
                    .product-input {
                        width: 50%;
                    }
                }
            }
        }
    }
}
@media (max-width: 450px) {
    .product-details {
        padding: 50px 0px;
    }
}
@media (max-width: 350px) {
    .navButtons {
        .btn-outline-primary {
            font-size: 0.7rem;
        }
        .btn-prev {
            margin-right: 0.3rem !important;
        }
    }
}
@media (max-width: 310px) {
    .navButtons {
        .btn-prev {
            margin-right: 0.3rem !important;
        }
        .btn-outline-primary {
            padding: 0.3rem;
        }
    }
}
@media (min-width: 768px) and (max-width: 1000px) {
    .navButtons {
        .btn-prev {
            margin-right: 0.3rem !important;
        }
        .btn-outline-primary {
            font-size: 0.7rem;
        }
    }
}
