@import "./mixins/mixins.scss";

.card-box {
    max-width: 335px;
    padding: 0.25rem;
    .card {
        box-shadow: inset -2px -2px #ccc;
        .card-img {
            height: 175px;
            .card-img-wrapper {
                margin: 0 auto;
                .card-img-content {
                    max-height: 175px;
                    max-width: 100%;
                }
            }
        }
        .card-title {
            @include themify($themes) {
                color: themed("productTitle");
            }
        }
        .card-text {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 992px) and (max-width: 1475px) {
    .card {
        .card-img {
            height: 170px;
            .card-img-wrapper {
                .card-img-content {
                    max-height: 170px;
                }
            }
        }
    }
}

@media (min-width: 1839px) {
    .card {
        max-width: 320px;
    }
}

@media (max-width: 645px) {
    .card {
        .card-img {
            height: 150px;
            .card-img-wrapper {
                .card-img-content {
                    max-height: 150px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .card {
        .card-img {
            height: 150px;
            .card-img-wrapper {
                .card-img-content {
                    max-height: 150px;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .card-box {
        padding: 2px 1px;
        .card {
            padding: 0px;
            .card-img {
                height: 90px;
                .card-img-wrapper {
                    .card-img-content {
                        max-height: 90px;
                    }
                }
            }
            .card-title {
                font-size: 15px;
                margin-bottom: 2px;
            }
            .card-text {
                strong {
                    font-size: 11px;
                }
            }
            .card-available-quantity {
                font-size: 11px;
                margin-bottom: 10px;
            }
        }
    }
}
