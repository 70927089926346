@import './mixins/mixins.scss';

.order-options {
    margin-top: 20px;
    margin-bottom: 20px;
    @include themify($themes) {
        background-color: themed('orderOptionsBg');  
    }
    .select-hidden {
        display: none;
    }
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    .options-header {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .out-of-ue-info{
        font-size: 14px;
    }
    .button-parent{
        border: none;
        .order-button, .btn-outline-primary {
            @include themify($themes) {
                background-color: themed('orderOptionsBtnBg');  
                color:themed('orderOptionsBtnFontC');  
                border-color:themed('orderOptionsBtnBorderC');
                &:hover{
                    color:themed('orderOptionsBtnFontCH');  
                    background-color: themed('orderOptionsBtnBgH');  
                }
            }
            font-size: 16px;
            text-align: left;
            padding:0.375rem 0.75rem;
            width:100%;
            text-align: center;
            padding:0.375rem 0.75rem;
            transition: 0.3s;
            border: 0.1rem solid;
            border-radius: 1px;  
            &:disabled{
                color:#ced4da;
                border-color: #ced4da;
                &:hover{
                    color:#ced4da;
                    background-color: transparent !important;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .order-options {
        width: 87%;
        margin: 20px auto 20px auto;
    }
}
@media (max-width: 450px) {
    .btn, .custom-select{
        font-size: 14px;
    }
    .title, h4{
        font-size:17px;
    }
}