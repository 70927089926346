@import "./mixins/mixins.scss";

button:focus {
    outline: none;
}

.image-gallery-slide {
    min-height: 350px;
    display: flex;
    align-items: center;
    border: 20px solid white;
    justify-content: center;
}

.image-gallery-thumbnail-inner {
    min-height: 50px;
    display: flex;
    align-items: center;
}

.image-gallery-right-nav {
    @include themify($themes) {
        color: themed("carouselC");
    }
}

.image-gallery-right-nav:hover::before {
    @include themify($themes) {
        color: themed("carouselC");
    }
}

.image-gallery-left-nav:hover::before {
    @include themify($themes) {
        color: themed("carouselC");
    }
}

.image-gallery-thumbnail.active {
    @include themify($themes) {
        border-color: themed("carouselC");
    }
}

.image-gallery-content.fullscreen {
    text-align: center;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e4e4e6;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
    @include themify($themes) {
        color: themed("carouselC");
    }
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
}

@media (min-width: 1199px) {
    .image-gallery-content.fullscreen img {
        margin: 0 auto;
        text-align: center;
    }
}
@media (min-width: 1400px) {
    .image-gallery-swipe {
        width: 500px;
    }
}

.image-gallery-content.fullscreen .image-gallery-slide {
    background: #e4e4e6;
}
