@import "./mixins/mixins.scss";

.order-summary {
    min-height: calc(100vh - 57px);
    padding-top: 80px;
    //background: url(/static/media/bg_top_2.850d8c2c.jpg) center no-repeat;
    @include themify($themes) {
        background-color: themed("mainBackgroundColor");
    }
    background-size: cover;
    .title-header {
        @include themify($themes) {
            color: themed("title");
        }
    }
    .summary-item-info {
        background-color: #f7f7f7;
    }
    .order-summary-text {
        @include themify($themes) {
            color: themed("title");
        }
        //font-size: 16px;
        // .summary-text-value {
        //     color: #ccc;
        // }
    }
    li {
        margin-bottom: 10px;
    }
    hr {
        @include themify($themes) {
            border-color: themed("hrBorderC");
        }
    }
    .summary-table {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        .table-row:nth-child(even) {
            background-color: rgb(242, 242, 242);
        }
        .table-row:nth-child(odd) {
            background-color: rgb(255, 255, 255);
        }
        th {
            padding-left: 5px;
        }
        td {
            padding-left: 5px;
        }
        .summary-header {
            @include themify($themes) {
                background-color: themed("tableHeader");
                color: themed("tableHeaderColor");
            }
            height: 40px;
            padding: 5px 5px;
        }
        .summary-img {
            margin: 10px;
            width: 60px;
            margin-right: 20px;
        }
        .summary-product-name {
            @include themify($themes) {
                color: themed("productTitle");
            }
        }
    }
}
// .order-text-value {
//     color: #CCC;
// }
.order-summary {
    @media (max-width: 991px) {
        .summary-table {
            td,
            th {
                padding: 2px;
                font-size: 13px;
            }
            .summary-img {
                width: 70px;
                margin: 5px;
            }
        }
    }
    @media (max-width: 550px) {
        .btn {
            width: 100%;
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 0.8rem;
            font-size: 14px;
        }
        .order-summary-text,
        b {
            font-size: 14px;
        }
        .title {
            font-size: 17px;
        }
        h2 {
            font-size: 22px;
        }
        .summary-table {
            td,
            th {
                padding: 2px;
                font-size: 11px;
            }
            .summary-img {
                width: 50px;
                margin: 5px;
            }
        }
        ol {
            padding-left: 15px;
        }
    }
    @media (max-width: 400px) {
        ol {
            padding-left: 15px;
        }
        .summary-table {
            td,
            th {
                padding: 2px;
                font-size: 9px;
            }
            .summary-img {
                width: 50px;
                margin: 3px;
            }
        }
    }
    @media (min-width: 551px) {
        .btn {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
        }
        .order-summary-text {
            font-size: 16px;
        }
        h4 {
            font-size: 20px;
        }
        ol {
            padding-left: 20px;
        }
    }
}
