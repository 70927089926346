@import './mixins/mixins.scss';

.footer {
    @include themify($themes) {
        background-color: themed('footerBgC');  
        color: themed('footerFontC');   
        border-color:themed('footerBorderC');   
    }
    border-top: 2px solid;
    text-transform: uppercase;
    width: 100%;
    bottom: 0;
    height: 55px;
    bottom: 0;
    .footer-text-line {
        display: flex;
        align-items: center;
        .footer-regulations a{
            @include themify($themes) { 
                color: themed('footerFontC');
            }
            &:hover {
                color: black;
            }
        }
    }
}
@media (max-width: 450px) {
    .footer-regulations {
        font-size: 12px;
    }
    .footer-contact {
        font-size: 12px;
    }
}
