@import "./mixins/mixins.scss";

.basket-container {
    .title {
        @include themify($themes) {
            color: themed("basketTitle");
        }
    }
    .item-number {
        position: absolute;
        font-size: 17px;
        display:none;
    }
    .white-bg {
        background-color: #ffffff;
        padding: 10px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    }
    .item-info {
        padding: 0;
    }
}
.basket-box {
    &:hover {
        .basket {
            @include themify($themes) {
                color: themed("basketIconHoverColorCP");
            }
        }
    }
    .basket {
        transition: 0.3s;
        @include themify($themes) {
            color: themed("basketColor");
        }
    }
    .basket-title {
        position: relative;
        left: -8px;
    }
    .basket-counter {
        position: relative;
        left: -12px;
        top: 5px;
        color: white;
        height: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 8px;
    }
    .counter-gray {
        background-color: #dee1e6;
        color: gray;
    }
    .counter-blue {
        @include themify($themes) {
            background-color: themed("basketItemQuantityBg");
        }
    }
}

.no-deco {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.cart {
    margin-top: 10px;
    .basket-header {
        width: 95%;
    }
    .collection-item {
        margin: 0 auto;
        width: 95%;
        .item-basket-img {
            width: 50%;
        }
    }
    .desc-col {
        @include themify($themes) {
            background-color: themed("basketDescBg");
            color: themed("basketDescColor");
            border-color: themed("basketDescBorderC");
        }
        border: 0.1rem solid;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        padding: 10px 10px 10px 8px;
        .text-right {
            button {
                font-size: 13px;
            }
        }
        .basket-quantity-input {
            @include themify($themes) {
                color: themed("title");
                border-color: themed("basketDescInputBorderC");
            }
            //width: 60px;
            padding: 4px;
            margin: 5px;
            background-color: transparent;
            &:hover {
                @include themify($themes) {
                    color: themed("title");
                }
            }
        }
    }
    .basket-button {
        font-size: 13px;
        height: 35px;
    }
    .product-quantity {
        margin: 0;
        padding: 5px;
        color: #004c97;
    }
}

.emptyBasket {
    padding-left: 60px;
}
.updateQuantity {
    width: 50px;
}
@media (max-width: 899px) {
    .basket-container {
        .basket-button {
            height: 35px;
        }
    }
}
@media (min-width: 992px) {
    .basket-container {
        .client-panel {
            margin-top: 125px !important;
        }
    }
}
@media (max-width: 767px) {
    .cart {
        .basket-header {
            width: 100%;
        }
        .collection-item {
            width: 100%;
            .item-basket-img {
                width: 50%;
                margin: 5px;
            }
        }
    }
    .item-number {
        left: 5px;
    }
}
@media (min-width: 768px) {
    .item-number {
        left: -30px;
    }
}
@media (max-width: 576px) {
    .img-box {
        padding-right: 0 !important;
    }
    .cart {
        .collection-item {
            .title {
                font-size: 18px;
            }
            p,
            b {
                font-size: 13px;
            }
        }
    }
    .basket-content {
        padding-right: 0;
    }
}
@media (min-width: 576px) {
    .img-box {
        padding-right: 0.25rem !important;
    }
    .basket-content {
        padding-right: 15px;
    }
}
