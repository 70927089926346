@import "./mixins/mixins.scss";

.modal-dialog {
    .modal-text-header{
        color: black;
    }
    .modal-text-content{
        color: black;
    }
}
