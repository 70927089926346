@import './mixins/mixins.scss';

$blue:rgb(3,88,153);

.basket-summary {
    margin: 0 auto;
    text-align: right;
    .summary-row{
        @include themify($themes) {
            background-color: themed('basketSummaryBg');
            border-color:themed('backetSummaryBorderC');
            color:themed('backetSummaryFontC');
        }
        border: .1rem solid;
        .summary-p{
            padding: 1rem 0.6rem !important;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        }
    }
}
.desc-col{
    span{
        margin-right:10px;
    }
}
.badge-blue{
    @include themify($themes) {
        background-color: themed('basketItemQuantityBg');
    }
    position: relative;
    top: 17px;
    left: -10px;
    color: white;
    font-size: 0.7rem;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .basket-summary {
        width: 100%;
    }
}
@media (min-width: 576px){
    .desc-col{
        span{
            font-size: 13px;
        }
    }
    .basket-quantity-input{
        height: 35px;
        width: 46px;
    }
}
@media (max-width: 575px){
    .desc-col{
        span{
            font-size: 12px;
        }
    }
    .basket-quantity-input{
        height: 35px;
        width: 43px;
    }
}